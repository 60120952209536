<script>
  import { t, locale, locales } from "../i18n.js";
  import { Link, navigate } from "svelte-routing";
  import { token } from "../stores.js";

  import { fade } from "svelte/transition";

  export let userdata;
  console.log(userdata);

  let warning = null;

  let result = null;

  async function fetchData() {
    const res = await fetch( userdata['base_path'] + "assets/php/auth.php", {
      method: "POST",
      body: JSON.stringify(userdata),
    });

    if (res.ok) {
      const json = await res.json();
      result = JSON.stringify(json);
      console.log(json);

      if (json.state == true) {
          localStorage.setItem("token", "1");
          localStorage.setItem("user_role", json.user_role);
          localStorage.setItem("user_name", json.user_name);
          token.set(localStorage.getItem("token"));
          
          navigate("/admin", { replace: true });
      } else {
        // navigate('/', { replace: true });
        console.log(json.error);
        if (json.error === "username") {
          warning = "username";
        } else {
          warning = "password";
        }
      }
    } else {
      throw new Error(data);
    }
  }

  let show_password = false;
  $: type = show_password ? "text" : "password";
  let value = "";

  function onInput(event) {
    value = event.target.value;
    userdata.password = event.target.value;
  }
</script>

<div id="login" in:fade={{ delay: 500, duration: 1000 }}>
  <img src="assets/images/woow-logo-black.svg" alt="logo" />
  <h1>{$t("login")}</h1>

  <form on:submit|preventDefault={fetchData}>
    <label for="username">{$t("username")}: </label>
    <input
      type="text"
      bind:value={userdata.username}
      class:warning={warning === "username"}
    />
    {#if warning === "username"}
      <span>užívateľ neexistuje !</span>
    {/if}
    <label for="password">{$t("password")}: </label>
    <input {type} on:input={onInput} class:warning={warning === "password"} />
    <div
      class="visibility {show_password ? 'visible' : ''}"
      on:click={() => (show_password = !show_password)}
    />
    {#if warning === "password"}
      <span>nesprávne heslo</span>
    {/if}

    <button>{$t("login.button")}</button>
  </form>
</div>

<style lang="scss">
  @import "../assets/scss/themes.scss";

  #login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 350px;
    height: 600px;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;

    img {
      height: 44px;
    }

    h1 {
      text-transform: uppercase;
      font-size: 15px;
      color: $text-color;
      font-weight: 400;
      text-align: center;
    }
  }
  form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px auto 0px;
  }
  label {
    margin-top: 20px;
    font-weight: 200;
    position: relative;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  input {
    padding: 15px 8px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #ccc;
    background: none;
    &:focus {
      outline: 1px $primary-color solid;
    }
  }

  input.warning {
    border-color: red;
  }

  span {
    color: $danger;
  }
  button {
    width: 100%;
    padding: 15px 20px;
    margin: 15px 0 30px 0;
    border: none;
    background-color: $primary-color;
    color: white;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    &:hover {
      background-color: darken($primary-color, 10);
    }
  }

  .visibility {
    width: 35px;
    height: 35px;
    background-color: transparent;
    position: relative;
    background-image: url("../../assets/icons/pass-visible.svg");
    background-repeat: no-repeat;
    background-position: center;
    top: -51px;
    right: 7px;
    align-self: self-end;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: lighten($text-color, 25);
    }
    &.visible {
      background-image: url("../../assets/icons/pass-hidden.svg");
    }
  }

  @media only screen and (max-width: 680px) {
    #login {
      width: 80%;
      height: 90%;

      img {
        width: 65%;
        height: auto;
      }
    }
  }
</style>
