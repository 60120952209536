<script>
    import { play_sound } from '../store.js';
    export let settings;
    export let userdata;
    let tmp = userdata;
    tmp = settings;

    let audio = new Audio(settings.components[11].active_sound); 
    audio.loop = true;
    
    let play;

    play_sound.subscribe(value => {
		play = value;
	});

    $: if (play == true && settings.components[11].active_sound != '' ) {
        // play background sound
        audio.play();
    }

    $: if (play == false) {
        // stop background sound
        audio.pause();
    }
       
</script>