<script>
    import { Link } from 'svelte-routing';
    import { token } from '../stores.js';

    localStorage.clear();
    token.set(localStorage.getItem('token'));

    console.log(localStorage);
</script>
<main>
    <h1>No access 🤕</h1>
<h2>
    Go back to <Link to="/">Home page</Link>
    or  <Link to="/login">Login</Link> to admin
</h2>
</main>



