<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    export let userdata;
    let loading_data = true;
    let settings;
    let test = [];

    let warning = null;
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        console.log(settings);
        loading_data = false;
        return json;
      } else {
        throw new Error(data);
      }
    }

    let generating_scenes = false;
    let generated_scenes = [];
  
    fetchData();

    async function merge_scenes() {
      generated_scenes = [];
      warning =  null;
      generating_scenes = true;
      console.log('spájam scény' + settings.components[10]["base_url_to_scenes"]);
      let data = {
        base_path : userdata['base_path'] + 'assets/',
        base_url_to_scenes : settings.components[10]["base_url_to_scenes"],
        final_name_suffix :  settings.components[10]["final_name_suffix"]
      }

      console.log(data);

      const res = await fetch(
        userdata['base_path'] + "assets/php/merge_scenes.php",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (res.ok) {
        const json = await res.json();
        console.log(JSON.stringify(json));
        

        if (json.status == true) {
          generated_scenes = json['generated_scenes'];
          addNotification({
                text: "Scény boli spojené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          warning = 'Priečinok neexistuje';
          addNotification({
                text: "Chyba : Počas spájania scén sa vyskytla chyba !",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }
      generating_scenes = false;  
    }

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia tour boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia tour neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}
    <main in:fade>
      <h1>{$t("components")['merge_scenes']['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      <div class="component">
        <label for="path">{$t("components")['merge_scenes']['base_path']}:</label>
        <input id="path" type="text" bind:value="{settings.components[10]["base_url_to_scenes"]}"/> 
      </div>
      <div class="component">
        <label for="name">{$t("components")['merge_scenes']['base_suffix']}:</label>
        <input id="name" type="text" bind:value="{settings.components[10]["final_name_suffix"]}"/> 
      </div>
      <button id="merge-scenes" on:click="{() => merge_scenes()}"> <i class="fa-solid fa-object-group"></i>Spojiť scény </button>
      {#if generating_scenes}
         <p in:fade>generujem scény ... </p>
         <img class="preloader" src="../assets/icons/preloader.svg" alt="loader"/>
      {/if}
      {#if generated_scenes}
      <div id="thumbnails">
        {#each generated_scenes as item}
          <div class="item">
            <img src="{item}" class="scene_thumbnail" alt="thumbnail"/>
            <p>URL :<a href="{item}" target="_blank"> {item}</a></p>
          </div>
        {/each}
      </div>
      {/if}

      {#if warning}
      <p>{warning}</p>
      {/if}
      
         
      
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    #merge-scenes {
      margin: 20px auto;
    }
    #thumbnails {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 36px;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .scene_thumbnail {
          width: 400px;
          height: 200px;
        }
        p{
          font-size: 12px;
          margin: 10px 0;
          }
      }
      
    }

    .preloader {
        width: 60px;
        margin:  10px auto auto;
      }    
    
  </style>
  