<script>
    import { Link } from 'svelte-routing';
    import { token } from '../stores.js';

    const logout = () => {
        localStorage.clear();
        token.set(localStorage.getItem('token'));
    }
</script>

<header>
    <div class="nav left-nav">
        <i class="fa-solid fa-bars"></i>
    </div>
    <div class="nav right-nav">
        <i class="fa-solid fa-language"></i>
        <i class="fa-solid fa-bell active"></i>
        
        <Link class="logout" to="/login" on:click={logout}><i class="fa-solid fa-power-off"></i></Link>
    </div>
</header>

<style lang="scss">
    @import '../assets/scss/themes.scss';
    header { 
        position: relative;
        background-color: #fff;
        grid-area: header; 
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        order: 1;

        // vycentrovanie ikony v odkaze
        .logout {
            i {
                margin: auto;
                display: block;
            }
        }

        .nav {
            position: absolute;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            i {
                color: $text-color;
                font-size:20px;
                cursor: pointer;

                &:hover, &.active {
                    color: $link-hover;
                }
            }
        }

        .left-nav {
            
            left: 0px;
            
            
            justify-content: flex-end;
            
            margin-left: 16px;
        }

        .right-nav {
            
            right: 0px;
            
            justify-content: flex-end;
           
            margin-right: 16px;

            
        }
    }
</style>