<script>
    import { Route, Link } from 'svelte-routing';
    import accessDenied from './routes/accessDenied.svelte';
    import Tour from './routes/Tour.svelte';
    import { token } from './stores.js';

    export let path;

    let redirect = false;
    let url = window.location.pathname;

    if (url.indexOf("tour") >= 0 ||
        url.indexOf("praha") >= 0
    ) {
       // alert('áno');
        redirect = true;
    }

    
    // console.log(url);
    
    export let component;
    export let userdata;
    
    $: isAuthenticated = $token;
</script>

{#if isAuthenticated}
    
    {#if localStorage.getItem('user_role') === 'admin'}
        <Route path={path} component={component} {userdata} />

    {:else if localStorage.getItem('user_role') === 'subadmin'}
        <Route path={path} component={component} />
    {:else}
        <Route path={path} component={accessDenied} />
    {/if}

    <Route path={path} component={component} />
{:else}
    {#if redirect }
        <Route path={path} component={Tour} {userdata} />

    {:else}
        <Route path={path} component={accessDenied} {userdata} />
    {/if}

    
    
    <!-- <Route path={path} component={Tour} {userdata} /> -->
{/if}