<script>
    import { Link } from 'svelte-routing';
    import { token } from '../../stores.js';
    import { fade } from 'svelte/transition';

    import Aside from '../../layouts/_aside.svelte';
    import Header from '../../layouts/_header.svelte';

    const logout = () => {
        localStorage.clear();
        token.set(localStorage.getItem('token'));
    }
</script>
<div id="wrapper">
    <Aside/>
    <main in:fade>
        <h1>👋 Welcome {localStorage.getItem('user_name')}</h1>
    </main>
    <Header/>
</div>

<style lang="scss">
    
</style>