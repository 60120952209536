<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";

  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";

  import { getNotificationsContext } from "svelte-notifications";
  const { addNotification } = getNotificationsContext();

  let result;
  let test = [];

  async function fetchData() {
    console.log("ssg");
    const res = await fetch(
      "https://woowstudio.com/molly/assets/php/getUsers.php",
      {}
    );

    if (res.ok) {
      const json = await res.json();
      test = json;
      result = JSON.stringify(json);
      console.log(test);

      return json;
    } else {
      throw new Error(data);
    }
  }

  let user_to_delete = {
    id: null,
  };

  async function removeUser() {
    if (user_to_delete == "") {
      addNotification({
        text: "nebol zvolený užívateľ.",
        position: "top-center",
        removeAfter: 4000,
        type: "warning",
      });
    } else {
      const res = await fetch(
        "https://woowstudio.com/molly/assets/php/removeUser.php",
        {
          method: "POST",
          body: JSON.stringify(user_to_delete),
        }
      );

      if (res.ok) {
        const json = await res.json();

        result = JSON.stringify(json);
        console.log(result);

        if (json.status == true) {
          addNotification({
            text: "Užívateľ bol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });

          fetchData();
        } else {
          addNotification({
            text: "Užívateľ nebol vymazaný!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });
        }
      } else {
        throw new Error(data);
      }
    }
  }

  fetchData();
</script>

<div id="wrapper">
  <Aside />
  
  <main in:fade>
    <h1>{$t("aside.users")}</h1>

    <div class="all-users">
      <div class="add-user" on:click="{() => navigate("/add-user", { replace: true })}">
        <i class="fa-solid fa-user-plus" />
        <p>{$t("aside.user.add")}</p>
      </div>
      {#each test as user}
        <div class="user">
          <div class="left">
            <div class="user-icon"><i class="fa-solid fa-user-tie" /></div>
            <div class="info">
              <p class="name">{user.first_name} {user.last_name}</p>
              <p class="user-name">
                <span>{$t("user.name")}: </span>{user.user_name}
              </p>
            </div>
          </div>

          <div class="right">
            <div class="role">
              <span>{$t("user.role")}: </span>
              <p class="rola">{user.user_role}</p>
            </div>
            <div class="edit"><i class="fa-solid fa-pen-to-square" /></div>
            <div
              id={user.user_name}
              class="delete"
              on:click={(event) => (user_to_delete.id = event.target.id)}
              on:click={() => removeUser()}
            >
              <i class="fa-solid fa-trash-can" />
            </div>
          </div>
        </div>
      {/each}
    </div>
  </main>
  <Header />
</div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";
  main {
    h1 {
      color: $text-color;
    }

    .all-users {
      margin: 0 auto;
      width: calc(100% - 64px);
      max-width: 1300px;

      .add-user {
        display: flex;
        background-color: $success;
        width: max-content;
        color: white;
        padding: 15px 20px;
        border-radius: 6px;
        margin: 20px auto;
        cursor: pointer;
        &:hover {
          background-color: darken($success, 10);
        }
        i {
          font-size: 16px;
        }
        p {
          margin-left: 10px;
        }
      }

      .user {
        display: flex;
        justify-content: space-between;
        padding: 25px 10px;
        border-top: 1px solid lighten($border-color, 7);
        .left {
          display: flex;
          align-items: center;
          .user-icon {
            width: 40px;
            height: 40px;
            background-color: $text-color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: white;
          }
          .info {
            text-align: left;
            margin: 0 15px;
            .name {
              font-weight: 400;
            }
            .user-name {
              color: $text-color;
            }
          }
        }
        .right {
          display: flex;
          .role {
            display: flex;
            background-color: white;
            padding: 10px 20px;
            border: 1px solid $border-color;
            border-radius: 6px;
            span {
              margin-right: 3px;
            }
          }
          .edit,
          .delete {
            width: 40px;
            height: 40px;
            // background-color: tomato;
            margin-left: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: $text-color;
            cursor: pointer;
          }
          .edit {
            &:hover {
              background-color: $info;
              color: white;
            }
          }
          .delete {
            &:hover {
              background-color: $danger;
              color: white;
            }
          }
        }
      }
    }
  }
</style>
