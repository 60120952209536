<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/clearData.php",
        {
          method: "POST",
          body: JSON.stringify(settings),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

    console.log(userdata['base_path']);

    function clear_data() {
      console.log(settings);
      settings.scenes = [];
      settings.configuration.start_scene = null;
      settings.configuration.scenes['folder_path'] = 'scenes';
      settings.configuration.settings['base_path'] = userdata['base_path'];
      settings.configuration.settings.meta[0].value = 'Molly 3.0';
      settings.configuration.settings.meta[1].value = 'best VR platform';
      settings.configuration.settings.meta[2].value = 'assets/img/favicon-small.png';
      settings.configuration.settings.meta[3].value = 'assets/img/woow-logo.svg';
      settings.configuration.settings.meta[4].value = 'assets/img/woow-logo.svg';
      settings.configuration.settings.meta[5].value = 'Tour image alt';

      // Logo
      settings.components[0].enabled = true;
      settings.components[0].img = 'assets/img/woow-logo.svg';
      settings.components[0].target = '_blank';
      settings.components[0].url = 'https://www.woowstudio.com';

      // Sidebar
      settings.components[1].enabled = true;


      // Shortctus
      settings.components[4].enabled = true;
      settings.components[4].scenes = 'all';
      settings.components[4].titles = 'title';

      // Maps
      settings.components[7].enabled = true;
      settings.components[7].filter = true;
      settings.components[7].url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.9612241505793!2d17.11188391625986!3d48.130271159647734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c89fbe48bb973%3A0xfca70b18ec6266ab!2sWOOWSTUDIO%20s.r.o.!5e0!3m2!1sen!2ssk!4v1664787315687!5m2!1sen!2ssk';

      // Tour info
      settings.components[8].enabled = true;
      settings.components[8]['admin_info'] = 'all';
      settings.components[8].data.title = 'Tour info';
      settings.components[8].data.headline = 'Praesent libero urna, convallis at arcu vel, congue suscipit sem. Nulla malesuada ac ex tempus imperdiet. Duis erat nisl, suscipit vitae rhoncus vitae, tincidunt ac dolor. Integer consequat justo mauris, nec lobortis arcu rhoncus vel. Nunc fringilla non neque ut rhoncus. Suspendisse potenti.';
      settings.components[8].data.img = 'img/static_exterior0003_final.jpg';
      settings.components[8].data.subtitle = 'Suspendisse potenti.';
      settings.components[8].data.content = 'Praesent libero urna, convallis at arcu vel, congue suscipit sem. Nulla malesuada ac ex tempus imperdiet. Duis erat nisl, suscipit vitae rhoncus vitae, tincidunt ac dolor. Integer consequat justo mauris, nec lobortis arcu rhoncus vel. Nunc fringilla non neque ut rhoncus. Suspendisse potenti.<br /><br />Nulla malesuada ac ex tempus imperdiet. Duis erat nisl, suscipit vitae rhoncus vitae, tincidunt ac dolor. Integer consequat justo mauris, nec lobortis arcu rhoncus vel.';
      
      // Floorplan
      settings.components[9].enabled = false;
      settings.components[9].active_floorplan = null;
      settings.components[9].data = [];

      // Sound
      settings.components[11].enabled = false;
      settings.components[11].active_sound = null;
      settings.components[11].autoplay = false;
      settings.components[11].bg_sounds = [];

      console.log(settings);
    }

</script>
  
  <div id="wrapper">
    <Aside />

    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("admin_components")["clear_data"]["admin_title"]}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      <button class="clear_data" name="clear_data" on:click={() => clear_data()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['clear']}</button>
 
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    .clear_data {
      background-color: red;
    }

  </style>