<script>
  import { Link } from "svelte-routing";
  import { token } from "../../stores.js";
  import { t, locale, locales } from "../../i18n.js";
  import { fade } from "svelte/transition";

  import Aside from "../../layouts/_aside.svelte";
  import Header from "../../layouts/_header.svelte";

import { getNotificationsContext } from 'svelte-notifications';
const { addNotification } = getNotificationsContext();

export let userdata;

    let result;
    let selected = "user";

    let new_user = {
        user_name : '',
        user_password : '',
        first_name : '',
        last_name : '',
        user_role : selected
    };

    let timeOut = 300;
    
    let status = "";

    async function create_user() {

        if (
            new_user.user_name != '' &&
            new_user.user_password != '' &&
            new_user.first_name != '' &&
            new_user.last_name != ''
        ) {
            const res = await fetch( userdata['base_path'] + 'assets/php/createUser.php', {
                method: "POST",
                body: JSON.stringify(new_user),
		    })

            if (res.ok) {
                const json = await res.json()
                result = JSON.stringify(json);

                if (json.status == true) {

                    addNotification({
                        text: 'Užívateľ bol pridaný.',
                        position: 'top-center',
                        removeAfter: 4000,
                        type: 'success'
                    })

                    new_user.user_name = '';
                    new_user.user_password = '';
                }

                else {
                    
                    addNotification({
                        text: 'Užívateľ nebol pridaný, užívateľské meno existuje !',
                        position: 'top-center',
                        removeAfter: 4000,
                        type: 'danger'
                    })
                }
                
            } else {
                throw new Error(data);
            }

        }

        else {
            addNotification({
                    text: 'Vyplnťe všetky povinné polia!',
                    position: 'top-center',
                    removeAfter: 4000,
                    type: 'danger'
                })
        }
  }
</script>

<div id="wrapper">
  <Aside />
  
  <main in:fade>
    <h1>{$t("aside.user.add")}</h1>

        <!-- Používateľské meno  -->
        <div class="component">
          <label for="user_name">{$t("username")}*</label>
          <input id="user_name" type="text" bind:value="{new_user.user_name}" name="user_name">
        </div>

        <!-- Používateľské heslo  -->
        <div class="component">
          <label for="password">{$t("password")}*</label>
          <input type="password" bind:value="{new_user.user_password}" name="password">
        </div>

        <!-- Krstné meno  -->
        <div class="component">
          <label for="first_name">{$t("user.first.name")}*</label>
          <input type="text" bind:value="{new_user.first_name}" name="first_name">
        </div>

        <!-- Priezvisko  -->
        <div class="component">
          <label for="last_name">{$t("user.last.name")}*</label>
          <input type="text" bind:value="{new_user.last_name}" name="last_name">
        </div>

        <!-- Rola používateľa  -->
        <div class="component">
          <label for="role">{$t("user.role")}</label>
          <select name="role" id="role"  bind:value={selected} on:change="{() => new_user.user_role = selected}">
              <option selected value="user">user</option> 
              <option value="subadmin">subadmin</option> 
              <option value="admin">admin</option>
          </select>
        </div>

        <!-- Button -->
        <div class="component">
          <button on:click="{() => create_user()}">{$t("user.add.button")}</button>
        </div>
  </main>
  <Header />

  </div>

<style lang="scss">
  @import "../../assets/scss/themes.scss";


</style>
