<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    export let userdata;

    let loading_data = true;

    let settings;
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "assets/php/saveMeta.php",
        {
          method: "POST",
          body: JSON.stringify(settings.configuration.settings.meta),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia META boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia META neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    }; 
  
    fetchData();

    // upload loga na server
    async function uploadFile($type, $i) {
      let formData = new FormData();      
      
      switch ($type) {
        case 'favicon':
          formData.append("file", upload_favicon.files[0]);
          break;
        
        case 'image':
          formData.append("file", upload_image.files[0]);
          break;
        
          case 'twitter_image':
          formData.append("file", twitter_image.files[0]);
          break;
      
        default:
          break;
      }
      
  
      const res = await fetch(userdata['base_path'] + 'assets/php/saveImg.php', {
        method: "POST", 
        body: formData
      });    

      const inputs = document.querySelectorAll('#image, #favicon, #twitter_image' );

      inputs.forEach(input => {
        input.value = '';
      });

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
            text: "súbor bol úspešne uploadnutý!",
            position: "top-center",
            removeAfter: 4000,
            type: "success",
          });  
            
          if (json.file_url) {

            settings.configuration.settings.meta[$i].value = 'assets/'+json.file_url;    
            save_settings();
            
          }
        }

        else {
          addNotification({
            text: "Chyba: súbor nebol uploadnutý!",
            position: "top-center",
            removeAfter: 4000,
            type: "danger",
          });  
        }
      }  
    }
</script>
  
  <div id="wrapper">
    <Aside />
    
    <main in:fade>

      <h1>{$t("admin_components")['settings']['title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("admin_components")['settings']['save_button']}</button>


      {#if loading_data == false}
        {#each settings.configuration.settings.meta as {id, value}, i}

          {#if id == 'favicon'}
            <div class="component">
              <label for="favicon" class="recomended">Favicon</label> 
              <img src="{userdata['base_path'] + value}" alt="favicon" name="favicon" class="fav">
            </div>
            <div class="upload">
              <input id="upload_favicon" type="file" name="upload_favicon">  
              <button on:click="{() => uploadFile("favicon", i)}"> Upload </button>
            </div>
          {:else if id == 'image'}
            <div class="component">
              <label for="meta_image" class="recomended">Image (recommended : 1200 pixels x 627 pixels)</label> 
              <img src="{userdata['base_path'] + value}" alt="Meta data img" name="meta_image">
            </div>
            <div class="upload">
              <input id="upload_image" type="file" name="upload_image">  
              <button on:click="{() => uploadFile("image", i)}"> Upload </button>
            </div>
          {:else if id == 'twitter_image'}
            <div class="component">
              <label for="twiter_image" class="recomended">Twitter image (recommended : 1200 pixels x 627 pixels)</label> 
              <img src="{userdata['base_path'] + value}" alt="Twitter img" name="twiter_image">
            </div>
            <div class="upload">
              <input id="twitter_image" type="file" name="twitter_image" accept="image/ico, image/png, image/jpeg">  
              <button on:click="{() => uploadFile("twitter_image", i)}"> Upload </button>
            </div>
          {:else}
            <label for="{id}">{id}</label>
            <input type="text" name="{value}" bind:value="{value}">  
          {/if}

          
        {/each}
      {/if}

      
    </main>
    <Header />
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    .component{
      display: flex;
      flex-direction: column;
      img{
        max-height: 300px;
        align-self: flex-start;
        &.fav{
          max-height: 30px;
        }
      }

    }

  </style>
  